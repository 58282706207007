<template>
  <div v-if="announcements.length" class="recent-announcements">
    <AnnouncementTile
      v-for="announcement in announcements.getModels()"
      :key="announcement.tag19"
      :announcement="announcement"
      class="mb-4"
    />
    <p>
      <router-link
        :to="{ name: 'announcements-index' }"
        class="font-weight-bold"
      >
        More Announcements &raquo;
      </router-link>
    </p>
    <j-divider />
  </div>
</template>

<script>

// Components
import AnnouncementTile from '@/components/widgets/announcements/AnnouncementTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('announcements')

export default {
  name: 'RecentAnnouncements',
  // -------------
  // Components ==
  // -------------
  components: { 
    AnnouncementTile
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'announcements'
    ])
  },
  // ------------
  // created() ==
  // ------------
  created() {
    this.fetchAnnouncements('recent')
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchAnnouncements'
    ]),
    handleAddAssignment () {
      this.$router.push({ name: 'announcements-create' })
    }
  }
}
</script>

<style lang="scss" scoped>
.recent-announcements {
  font-size: 90%;
}
</style>