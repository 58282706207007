<template>
  <div class="current-assignments">
    <!-- My Assignments -->
    <AssignmentTable
      v-if="assignments.length"
      heading="My Assignments"
      :opps="currentAssignments"
      :hide="['date', 'assignee']"
      :show-table-header="false"
      :show-pagination="false"
      @draft="handleDraft"
      @confirm="handleConfirm"
      @cancel="handleRetire"
    />

    <!-- Comment modal for retiring assignments -->
    <RetireAssignmentDialog />
  </div>
</template>

<script>

// Components
import AssignmentTable from '@/components/widgets/Opps/AssignmentTable'
import RetireAssignmentDialog from '@/components/Opps/RetireAssignmentDialog'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('opps')

export default {
  name: 'CurrentAssignments',
  // -------------
  // Components ==
  // -------------
  components: {
    AssignmentTable,
    RetireAssignmentDialog
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    assignments: {
      type: Object,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'opp',
    ]),
    currentAssignments () {
      return this.$store.state.dashboard.currentAssignments
    },
    commentDialogVisible () {
      return this.$store.state.comments.commentDialogVisible
    }
  },
  // ------------
  // created() ==
  // ------------
  created() {
    this.fetchAssignments()
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchAssignments',
      'fetchOpp',
      'assignOpp',
      'confirmOpp',
      'retireAssignment',
      'cancelRetirement',
      'createDraft'
    ]),
    handlePropose () {
      this.$router.push({ name: 'opps-create' })
    },
    handleDraft (source) {
      this.createDraft(source)
    },
    handleConfirm (opp) {
      this.confirmOpp(opp)
    },
    handleRetire (opp) {
      console.log('retire')
      this.fetchOpp(opp.get('tag19'))
      this.$dialog.open('retire-assignment')
    },
    handleConfirmRetirement () {
      this.retireAssignment()
    },
    handleCancelRetirement () {
      this.cancelRetirement()
    }
  }
}
</script>
