<template>
  <ArticleTable
    heading="My Copy Desk"
    :articles="currentArticles"
    :count="false"
    :hide="['date','byline']"
    :show-table-header="false"
    :show-pagination="false"
  >
    <template #actions>
      <!-- Add Draft Button -->
      <j-button
        label="Add Article"
        type="header"
        icon="plus"
        class="float-right"
        :to="{ name: 'draft-create' }"
      />
    </template>
  </ArticleTable>
</template>

<script>

// Components
import ArticleTable from '@/components/widgets/articles/ArticleTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('dashboard')

export default {
  name: 'CopyDesk',
  // -------------
  // Components ==
  // -------------
  components: {
    ArticleTable
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'currentArticles'
    ])
  }
}
</script>
