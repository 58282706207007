<template>

  <!-- Confirm Retire Assignment (Dialog) -->
  <CommentDialog
    dialog-name="retire-assignment"
    :title="`Retire &quot;${opp.title}&quot;`"
    message="Please provide some information on this action..."
    :source="opp"
    save-text="Retire"
    @save="handleConfirmRetirement"
    @cancel="handleCancelRetirement"
  />

</template>

<script>

// Components
import CommentDialog from '@/components/panels/comments/CommentDialog'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('opps')

export default {
  name: 'RetireAssignmentDialog',
  // -------------
  // Components ==
  // -------------
  components: {
    CommentDialog
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'opp'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'retireAssignment',
      'cancelRetirement'
    ]),
    // Handlers
    handleConfirmRetirement () {
      this.retireAssignment()
    },
    handleCancelRetirement () {
      this.cancelRetirement()
    }
  }
}
</script>
