<template>
  <j-listing
    :listing="recentArticles"
    heading="Recently Published"
    :show-avatar="true"
    @selected="handleRecentArticle"
  >
    <template #actions>
      <!-- Edit Schedule Button -->
      <j-button
        label="Schedule"
        type="header"
        icon="calendar"
        class="float-right"
        :to="{ name: 'articles-schedule' }"
      />
    </template>

    <template #footer>
      <router-link
        :to="{ name: 'articles-index' }"
        class="font-weight-bold"
      >
        More articles &raquo;
      </router-link>
    </template>
  </j-listing>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('dashboard')

export default {
  name: 'RecentlyPublished',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'recentArticles'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    handleRecentArticle (index) {
      const tag19 = this.recentArticles.getModels()[index].get('tag19')
      this.$router.push({ name: 'articles-view', params: { tag19 }})
    }
  }
}
</script>
