<template>

  <!-- Featured Opportunities -->
  <div v-if="featuredOpps.length" class="featured-opportunities">

    <j-heading
      heading="Featured Opportunities"
    >
      <template #actions>

        <!-- View All Button -->
        <j-button
          label="View All"
          type="header"
          icon="list"
          class="float-right"
          :to="{ name: 'opps-featured' }"
        />

      </template>
    </j-heading>

    <!-- Featured Opps Loop -->
    <OppTile
      v-for="opp in featuredOpps.getModels()"
      :key="opp.tag19"
      :opp="opp"
      class="mb-4"
    >
      <template #footer>

        <!-- Link to item -->
        <router-link :to="{ name: 'opps-details', params: { tag19: opp.tag19 }}"><strong>Details</strong></router-link>

        <v-spacer />

        <!-- Request Button -->
        <OppRequest
          :opp="opp"
          class="my-2"
        />
      </template>
    </OppTile>

    <p>
      <router-link
        :to="{ name: 'opps-featured' }"
        class="font-weight-bold"
      >
        More Opportunities &raquo;
      </router-link>
    </p>
    <j-divider />
  </div>
</template>

<script>

// Components
import OppRequest from '@/components/widgets/Opps/OppRequest'
import OppTile from '@/components/widgets/Opps/OppTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('dashboard')

export default {
  name: 'featuredOpps',
  // -------------
  // Components ==
  // -------------
  components: {
    OppRequest,
    OppTile
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'featuredOpps',
    ])
  }
}
</script>
