<template>
  <!-- Dashboard -->
  <j-page
    title="Dashboard"
    :loading="loading"
    class="dashboard"
  >
    <v-col cols="6">

      <!-- Recent Announcements -->
      <RecentAnnouncements />

      <!-- Recent Articles -->
      <RecentlyPublished />

    </v-col>
    <v-col cols="6">

      <!-- My Assignments -->
      <CurrentAssignments :assignments="currentAssignments" />

      <!-- My Copy Desk -->
      <CopyDesk />

      <!-- Featured Opps -->
      <FeaturedOpps />

    </v-col>
  </j-page>
</template>

<script>

// Components
import CopyDesk from '@/components/panels/dashboard/CopyDesk'
import CurrentAssignments from '@/components/panels/dashboard/CurrentAssignments'
import FeaturedOpps from '@/components/panels/dashboard/FeaturedOpps'
import RecentAnnouncements from '@/components/panels/dashboard/RecentAnnouncements'
import RecentlyPublished from '@/components/panels/dashboard/RecentlyPublished'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('dashboard')

export default {
  name: 'Dashboard',
  // -------------
  // Components ==
  // -------------
  components: {
    CopyDesk,
    CurrentAssignments,
    FeaturedOpps,
    RecentAnnouncements,
    RecentlyPublished
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.fetchDashboard()
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Dashboard'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapState([
      'currentAssignments'
    ]),
    ...mapGetters([
      'loading'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Vuex
    ...mapActions([
      'fetchDashboard'
    ])
  }
}
</script>
